const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['checkAuth'] = require('../middleware/checkAuth.js')
middleware['checkAuth'] = middleware['checkAuth'].default || middleware['checkAuth']

middleware['checkBankAccount'] = require('../middleware/checkBankAccount.js')
middleware['checkBankAccount'] = middleware['checkBankAccount'].default || middleware['checkBankAccount']

middleware['themeRoutes'] = require('../middleware/themeRoutes.js')
middleware['themeRoutes'] = middleware['themeRoutes'].default || middleware['themeRoutes']

export default middleware
